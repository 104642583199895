<template>
  <v-card>
    <v-overlay absolute :value="progressing" opacity="0.3">
      <v-progress-circular indeterminate color="primary" class="mb-0" size="64"></v-progress-circular>
    </v-overlay>

    <div class="d-flex align-center">
      <v-card-title class="text-h5">{{ $t('dashboard.UvAlerts') }}</v-card-title>
      <v-card-title class="ms-auto">
        <ExportExcel :fetch="getExcel" :columns="columns" file-name="uv_dose_alerts" />
      </v-card-title>
    </div>

    <v-card-text>
      <search-form>
        <div class="d-inline-flex flex-wrap mb-3">
          <date-picker v-model="search.createdMin" search-form :label="$t('shared.createdMin')" />
          <date-picker v-model="search.createdMax" search-form :label="$t('shared.createdMax')" />
          <auto-complete
            v-if="!userId"
            v-model="search.userId"
            item-text="fullName"
            :search="userSearch"
            :label="$t('users.user')"
            search-form
          />
          <auto-complete
            v-if="!organizationId"
            v-model="search.organizationId"
            :search="organizationSearch"
            :label="$t('organizations.organization')"
            search-form
          />

          <auto-complete
            v-if="!workingSiteId"
            v-model="search.workingSiteId"
            :search="workingSiteSearch"
            :label="$t('workingSites.workingSite')"
            search-form
          />
        </div>

        <div class="ma-1">
          <v-btn color="success" dark @click="getData" small :loading="progressing">
            <v-icon small class="me-1"> mdi-filter </v-icon>
            {{ $t('shared.applyFilter') }}
          </v-btn>
        </div>
      </search-form>

      <VChart type="pie" :data="chartData" :options="chartOptions" ref="chart" :plugins="plugins"> </VChart>
    </v-card-text>
  </v-card>
</template>

<script>
import VChart from '@/components/Charts/VChart'
import ChartDataLabels from 'chartjs-plugin-datalabels'
export default {
  name: 'UvAlerts',
  components: {
    VChart
  },
  props: {
    organizationId: {type: [Number, String], default: null},
    workingSiteId: {type: [Number, String], default: null},
    userId: {type: [Number, String], default: null}
  },
  data() {
    return {
      plugins: [ChartDataLabels],
      disabledIndexes: [],
      search: {
        createdMax: '2023-01-01T00:00:00',
        createdMin: '2021-01-01T00:00:00',
        alertType: 'UV_DOSE',
        userId: this.userId || null,
        organizationId: this.organizationId || null,
        workingSiteId: this.workingSiteId || null
      },
      chartData: {
        labels: [],
        datasets: [
          {
            label: this.$t('dashboard.activityUnderSun'),
            backgroundColor: ['#4dc9f6', '#f67019', '#f53794', '#537bc4', '#acc236', '#166a8f', '#00a950', '#58595b', '#8549ba'],
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'right'
          },
          datalabels: {
            anchor: 'center',
            backgroundColor: function (context) {
              return context.dataset.backgroundColor
            },
            color: 'white',
            display: function (context) {
              const dataset = context.dataset
              const count = dataset.data.length
              const value = dataset.data[context.dataIndex]
              return value > count * 1
            },
            font: {
              weight: 'bold'
            },
            padding: 6,
            formatter(value) {
              return `${value}%`
            }
          }
        }
      },
      columns: [
        {
          field: 'option',
          title: this.$t('dashboard.title'),
          formatter: (value) => {
            return this.$t(`alerts.UV_DOSE.${value}`)
          }
        },
        {field: 'percent', title: this.$t('dashboard.percent')}
      ]
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getExcel() {
      this.progressing = true
      const filter = this.$filter()
      try {
        return await this.$api.reports.getAlerts(filter)
      } catch (error) {
        this.$showError(error)
      } finally {
        this.progressing = false
      }
    },
    workingSiteSearch(filter) {
      return this.$api.workingSites.getAllInfo(filter)
    },
    organizationSearch(filter) {
      return this.$api.organizations.getAllInfo(filter)
    },
    userSearch(filter) {
      return this.$api.users.getAllInfo(filter)
    },
    getData() {
      this.progressing = true
      const filter = this.$filter()
      return this.$api.reports.getAlerts(filter).then((res) => {
        this.chartData.labels = res.map((item) => this.$t(`alerts.UV_DOSE.${item.option}`))
        this.chartData.datasets[0].data = res.map((item) => item.percent)
        this.progressing = false
      })
    },
    isDisabled(index) {
      return this.disabledIndexes.includes(index)
    },
    toggleData(index) {
      if (this.disabledIndexes.includes(index)) {
        this.disabledIndexes.splice(this.disabledIndexes.indexOf(index), 1)
      } else {
        this.disabledIndexes.push(index)
      }
      this.$refs.chart.toggleData(index)
    }
  }
}
</script>

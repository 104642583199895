<template>
  <v-card>
    <v-overlay absolute :value="progressing" opacity="0.3">
      <v-progress-circular indeterminate color="primary" class="mb-0" size="64"></v-progress-circular>
    </v-overlay>

    <div class="d-flex align-center">
      <v-card-title class="text-h5">{{ $t('dashboard.activityUnderSunTitle') }}</v-card-title>
      <v-card-title class="ms-auto">
        <ExportExcel :fetch="getExcel" :columns="columns" file-name="activity_chart" />
      </v-card-title>
    </div>

    <v-card-text>
      <search-form>
        <div class="d-inline-flex flex-wrap mb-3">
          <c-input select v-model="search.timeGroupBy" :items="periodItems" :label="$t('shared.active')" search-form />
          <date-picker v-model="search.createdMin" search-form :label="$t('shared.createdMin')" />
          <date-picker v-model="search.createdMax" search-form :label="$t('shared.createdMax')" />
          <auto-complete
            v-if="!userId"
            v-model="search.userId"
            item-text="fullName"
            :search="userSearch"
            :label="$t('users.user')"
            search-form
          />
          <auto-complete
            v-if="!organizationId"
            v-model="search.organizationId"
            :search="organizationSearch"
            :label="$t('organizations.organization')"
            search-form
          />

          <auto-complete
            v-if="!workingSiteId"
            v-model="search.workingSiteId"
            :search="workingSiteSearch"
            :label="$t('workingSites.workingSite')"
            search-form
          />
        </div>

        <div class="ma-1">
          <v-btn color="success" dark @click="getUnderSun" small :loading="progressing">
            <v-icon small class="me-1"> mdi-filter </v-icon>
            {{ $t('shared.applyFilter') }}
          </v-btn>
        </div>
      </search-form>

      <VChart :data="chartData" :options="chartOptions" ref="chart">
        <template #header>
          <div class="mb-4">
            <div class="d-flex align-center mb-2">
              <div class="d-flex me-auto">
                <div
                  v-for="(dataset, index) in chartData.datasets"
                  :key="dataset.label"
                  class="d-flex align-center me-4 cursor-pointer"
                  @click="toggleDataset(dataset, index)"
                >
                  <div
                    class="d-inline-block me-1 text-decoration-line-through rounded"
                    :class="{'opacity-25': !dataset.active}"
                    :style="{width: '20px', height: '20px', backgroundColor: dataset.backgroundColor}"
                  />
                  <span :class="{'text-decoration-line-through': !dataset.active}">{{ dataset.label }}</span>
                </div>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
        </template>
      </VChart>
    </v-card-text>
  </v-card>
</template>

<script>
import VChart from '@/components/Charts/VChart'
import moment from 'moment-jalaali'
export default {
  name: 'ActivityChart',
  components: {
    VChart,
  },
  props: {
    organizationId: {type: [Number, String], default: null},
    workingSiteId: {type: [Number, String], default: null},
    userId: {type: [Number, String], default: null}
  },
  data() {
    return {
      search: {
        timeGroupBy: 'DAILY',
        createdMax: '2023-01-01T00:00:00',
        createdMin: '2021-01-01T00:00:00',
        userId: this.userId || null,
        organizationId: this.organizationId || null,
        workingSiteId: this.workingSiteId || null
      },
      periodItems: [
        {text: this.$t('shared.daily'), value: 'DAILY'},
        {text: this.$t('shared.week'), value: 'WEEKLY'},
        {text: this.$t('shared.month'), value: 'MONTHLY'},
        {text: this.$t('shared.year'), value: 'YEARLY'}
      ],
      chartData: {
        labels: [],
        metaLabels: [],
        datasets: [
          {
            active: true,
            value: 'activityUnderSun',
            label: this.$t('dashboard.activityUnderSun'),
            backgroundColor: '#67B7DC',
            data: []
          },
          {
            active: true,
            value: 'totalActivity',
            label: this.$t('dashboard.totalActivity'),
            backgroundColor: '#8067DC',
            data: []
          }
        ]
      },
      chartOptions: {
        maxBarThickness: 40,
        borderRadius: 5,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const {
                  dataset: {label},
                  parsed: {y}
                } = context

                const hours = Math.floor(y)
                const minutes = Math.floor((y - hours) * 60)
                const seconds = Math.floor((y - hours - minutes / 60) * 3600)
                const clock = `${hours}:${minutes}:${seconds}`

                return `${label}: ${clock}`
              }
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value) {
                return `${value}H`
              }
            }
          },
          x: {
            stacked: true,
            display: true,
            position: 'bottom'
          },
          x1: {
            display: true,
            position: 'top',
            ticks: {
              callback: function (_value, index) {
                return this.chart.data.metaLabel[index]
              }
            },
            grid: {
              drawOnChartArea: false
            }
          }
        }
      },
      columns: [
        {field: 'start', title: this.$t('dashboard.start')},
        {field: 'end', title: this.$t('dashboard.end')},
        {field: 'countActiveUser', title: this.$t('dashboard.activeUserCount')},
        {field: 'sumActivity', title: this.$t('dashboard.totalActivity')},
        {field: 'sumUnderSunActivity', title: this.$t('dashboard.activityUnderSun')}
      ]
    }
  },
  created() {
    this.getUnderSun()
  },
  methods: {
    workingSiteSearch(filter) {
      return this.$api.workingSites.getAllInfo(filter)
    },
    organizationSearch(filter) {
      return this.$api.organizations.getAllInfo(filter)
    },
    userSearch(filter) {
      return this.$api.users.getAllInfo(filter)
    },
    async getExcel() {
      this.progressing = true
      const filter = this.$filter()
      try {
        return await this.$api.reports.getUsersActivity(filter)
      } catch (error) {
        this.$showError(error)
      } finally {
        this.progressing = false
      }
    },
    getUnderSun() {
      this.progressing = true
      const filter = this.$filter()
      return this.$api.reports.getUsersActivity(filter).then((res) => {
        this.chartData.labels = res.map((item) => moment.utc(item.start).format('YYYY/MM/DD'))
        this.chartData.datasets[0].data = res.map((item) => this.toHours(item.sumUnderSunActivity))
        this.chartData.datasets[1].data = res.map((item) => this.toHours(item.sumActivity))
        this.chartData.metaLabel = res.map((item) => this.$t('dashboard.xPersons', {x: item.countActiveUser}))
        this.progressing = false
      })
    },
    toHours(clock) {
      const [hours, minutes, seconds] = clock.split(':')
      return parseInt(hours) + parseInt(minutes) / 60 + parseInt(seconds) / 3600
    },
    toClock(value) {
      const hours = Math.floor(value)
      const minutes = Math.floor((value - hours) * 60)
      const seconds = Math.floor((value - hours - minutes / 60) * 3600)
      return `${hours}:${minutes}:${seconds}`
    },
    toggleDataset(dataset, index) {
      dataset.active = !dataset.active
      this.$refs.chart.toggleDataset(index)
    }
  }
}
</script>
